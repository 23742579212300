<template>
    <div>
        <header-view></header-view>
        <div class="xian"></div>
        <div class="nav">
            <span class="old">首页></span><span class="new">新闻列表</span>
        </div>
        <div class="news-list">
            <div class="news-list-item" @click="toPath('/detail?id=' + item.id)" v-for="(item,index) in newsLists" :key="index">
                <div class="list-item-left">
                    <img :src="item.img_url" alt="">
                </div>
                <div class="list-item-center">
                    <div class="center-top">
                        {{ item.title }}
                    </div>
                    <div class="center-center">
                        {{ item.desc }}
                    </div>
                    <div class="center-bottom">
                        <span>
                            时间：{{ item.create_date }}
                        </span>
                        <span class="num">
                            阅读：{{ item.views }}
                        </span>
                    </div>
                </div>
                <div class="list-item-right">
                    <div @click="toPath('/detail?id=' + item.id)" class="item-right-button">
                        查看更多
                    </div>
                </div>  
            </div>
        </div>
        <div class="page">
            <el-pagination
                background
                layout="prev, pager, next"
                prev-text="上一页"
                next-text="下一页"
                :current-page="search.page"
                :page-size="search.page_size"
                @current-change="changePage"
                :total="total">
            </el-pagination>
        </div>
        <footer-view></footer-view>
    </div>
</template>
<script>
 import {getNews} from '@/api/index/index'
 import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
    export default {
        components : {
            HeaderView,
            FooterView
        },
        data() {
            return {
                newsLists : [],
                search : {
                    page : 1,
                    page_size : 10
                },
                total : 0
            }
        },
        created() {
            this.getNews()
        },
        mounted() {
        },
        methods : {
            getNews() {
                getNews(this.search).then(res => {
                    this.newsLists = res.data.lists
                    this.total = res.data.total
                })
            },
            changePage(page) {
                this.search.page = page
                this.getNews()
            },
            toPath(path) {
                this.$router.push({path : path})
            }
        
           
        }
    }
</script>
<style scoped>
.xian{
    width: 100%;
    margin: 0 auto;
    height: 2px;
    box-shadow: 0px 2px 4px 0px rgba(40,40,40,0.15); 
}
.nav{
    width: 1200px;
    height: 65px;
    text-align: left;
    margin: 0 auto;
    font-size: 14px;
    line-height: 65px;
}
.old{
    color: #717171;
}
.new{
    color: #8ce2a9;
}
.news-list{
    width: 1200px;
    min-height: 2033px;
    background-color: #fff;
    margin:0 auto;
}
.news-list-item{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #EFEFEF;
}
.list-item-left{
    width: 245px;
    height: 153px;
}
.list-item-left img {
    width: 245px;
    height: 153px;
}
.list-item-center{
    width: 708px;
    text-align: left;
}
.center-top{
    font-size: 16px;
    color: #333;
    font-weight: 500;
    -webkit-line-clamp: 1; 
    text-overflow: ellipsis;
}
.center-center{
    height: 68px;
    -webkit-line-clamp: 3; 
    text-overflow: ellipsis;
    color: #888888;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 35px;
}
.center-bottom{
    color: #888888;
    font-size: 14px;
}
.num {
    margin-left: 17px;
}
.list-item-right{
    width: 112px;
}
.item-right-button{
    width: 112px;
    height: 36px;
    border-radius: 18px;
    background: linear-gradient(90deg,#7dc9f9, #69e6a3);
    font-size: 14px;
    color: #fff;
    line-height: 36px;
    margin-top: 94px;
    cursor: pointer;
}
.page{
    margin-bottom: 8px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #69E6A3;
}
@media only screen and (max-width:750px){
    .nav{
        width: 96%;
        margin: 0 auto;
    }
 
    .news-list{
        width: 100%;
       
    }
    .news-list-item{
        width: 96%;
        margin:0 auto;
        padding: 0;
        padding-top: 10px;
    }
    .list-item-left,.list-item-center,.list-item-right{
        width: 100%;
    }
    .list-item-left{
        height: 94px;
    }
    .list-item-left img{
        width: 150px;
        height: 94px;
    }
    .list-item-right{
        display: none;
    }
    .center-bottom{
        display: none;
    }
    .center-center{
        height: 40px;
    }
    .news-list{
        min-height: 100%;
    }
}
</style>