var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-content"},[_vm._m(0),_c('div',{staticClass:"header-right"},[_c('div',{staticClass:"header-right-item",on:{"click":function($event){return _vm.toPathId('#')}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"header-right-item",on:{"click":function($event){return _vm.toPathId('#chanpin')}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"header-right-item",on:{"click":function($event){return _vm.toPathId('#guanyu')}}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"header-right-item",on:{"click":function($event){return _vm.toPathId('#fuwu')}}},[_vm._m(7),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-left"},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-item"},[_c('span',[_vm._v("HOME")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("产品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-item"},[_c('span',[_vm._v("PRODUCT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("关于")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-item"},[_c('span',[_vm._v("ABOUT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-item"},[_c('span',[_vm._v("SERVICE")])])
}]

export { render, staticRenderFns }