import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';  
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import '../src/assets/css/main.css'



Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VideoPlayer);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
