<template>
    <div>
        <div class="header">
            <div class="header-content">
                <div class="header-left">
                    <img src="@/assets/images/logo.png" alt="" />
                </div>
                <div class="header-right">
                    <div class="header-right-item" @click="toPathId('#')">
                        <div><span>首页</span></div>
                        <div class="en-item"><span>HOME</span></div>
                    </div>
                    <div
                        class="header-right-item"
                        @click="toPathId('#chanpin')"
                    >
                        <div><span>产品</span></div>
                        <div class="en-item"><span>PRODUCT</span></div>
                    </div>
                    <div class="header-right-item" @click="toPathId('#guanyu')">
                        <div><span>关于</span></div>
                        <div class="en-item"><span>ABOUT</span></div>
                    </div>
                    <div class="header-right-item" @click="toPathId('#fuwu')">
                        <div><span>服务</span></div>
                        <div class="en-item"><span>SERVICE</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    created() {
        this.$nextTick(() => {
            this.getLocal();
        });
    },
    mounted() {
        var hash = window.location.hash;
        var index = hash.lastIndexOf("#");
        if (index != -1) {
            this.$nextTick(() => {
                window.addEventListener("scroll", this.handleScroll);
            });
        }
    },

    methods: {
        toPathId(id) {
            this.$emit('toPathId', id)
        },

        getLocal() {
            var hash = window.location.hash;
            var index = hash.lastIndexOf("#");
            var id = hash.substring(index + 1, hash.length + 1);
            var div = document.getElementById(id);
            if (index != -1) {
                div.scrollIntoView(true);
            }
        }
    }
}

</script>
<style scoped>
.header {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 9999;
}
.header-content {
    width: 1200px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-left img {
    width: 115px;
    height: 43px;
}
.header-right {
    width: 526px;
    display: flex;
    color: #5c6c59;
    font-size: 14px;
    justify-content: space-between;
}
.header-right-item {
    width: 60px;
    height: 46px;
    padding-top: 16px;
    cursor: pointer;
}
.en-item {
    font-size: 8px;
}
.header-right-item:hover {
    color: #fff;
    background: linear-gradient(137deg, #7dc9f9 4%, #69e6a3 97%);
}
@media screen and (max-width: 750px) {
    .header-left img {
        margin: 5px;
    }
    .header-right {
        width: 65% !important;
    }

    .header-content {
        width: 100%;
    }
}
</style>
