import request from '@/utils/request'

/**
 * 
 * 新闻列表
 * @param {*} data 
 * @returns 
 */
export function getNews(data) {
    return request({
      url: '/api/home/getNews',
      method: 'post',
      data
    })
}

/**
 * 新闻详情
 * @param {*} data 
 * @returns 
 */
export function newDetail(data) {
  return request({
    url: '/api/home/newDetail',
    method: 'post',
    data
  })
}

/**
 * 用户留言
 * @returns 
 */
export function getMess() {
  return request({
    url: '/api/home/getMess',
    method: 'post',
  })
}

/**
 * 首页新闻
 * @param {*} data 
 * @returns 
 */
export function homeNews(data) {
  return request({
    url: '/api/home/homeNews',
    method: 'post',
    data
  })
}
/**
 * 轮播图
 * @param {*} data 
 * @returns 
 */
export function getBanners(data) {
  return request({
    url: '/api/home/getBanners',
    method: 'post',
    data
  })
}

/**
 * 友情链接
 * @param {*} data 
 * @returns 
 */
export function getLinks(data) {
  return request({
    url: '/api/home/getLinks',
    method: 'post',
    data
  })
}

/**
 * 合作伙伴
 * @param {*} data 
 * @returns 
 */
export function getPartner() {
  return request({
    url: '/api/home/getPartner',
    method: 'post',
  })
}






