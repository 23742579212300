<template>
    
    <div >
        <header-view></header-view>
        <div class="detail-content">

        
        <div class="xian">

        </div>
        <div class="nav">
            <span class="old">首页></span>  <span class="old">新闻列表></span> <span class="new">新闻详情</span>
        </div>
        <div class="new-detail">
            <div class="new-detail-title">
                <div class="title-text">
                    {{detail.title}}
                </div>
                <div class="title-text-time">
                    <span>时间：{{ detail.create_date }}</span>
                    <span class="num">{{ detail.views }}</span>
                </div>
            </div>
            <div class="article" v-html="detail.content">
            </div>
        </div>
    </div>
        <footer-view></footer-view>
    </div>
</template>
<script>
 import {newDetail} from '@/api/index/index'
 import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
export default {
    components : {
        HeaderView,
        FooterView
    },
    data() {
        return {
            id : 0,
            detail : {}
        }
    },
    created() {
        this.id = this.$route.query.id
        this.newDetail()
    },
    methods : {
        newDetail() {
            newDetail({id : this.id}).then(res => {
                this.detail = res.data
            })
        }
    }
}
</script>

<style scoped>
.detail-content{
    background-color: #f8f8f8;
}
.xian{
    width: 100%;
    margin: 0 auto;
    height: 2px;
    box-shadow: 0px 2px 4px 0px rgba(40,40,40,0.15); 
}

.nav{
    width: 1200px;
    height: 65px;
    text-align: left;
    margin: 0 auto;
    font-size: 14px;
    line-height: 65px;
}
.old{
    color: #717171;
}
.new{
    color: #8ce2a9;
}
.new-detail{
    width: 1160px;
    min-height: 1931px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    padding:20px;
}
.new-detail-title{
    height: 129px;
    background-color: #fafafa;
    overflow: hidden;
}
.title-text{
    font-size: 24px;
    margin-top: 25px;
    margin-bottom: 23px;
    font-weight: 500;
}
.title-text-time{
    color: #888888;
    font-size: 14px;

}
.num{
    margin-left: 28px;
}
.article{
    width: 1100px;
    padding:35px 30px 0 30px;
}
@media only screen and (max-width:750px){
    .nav {
        width: 100%;
    }
    .new-detail{
        width: 96%;
        padding: 0;
        margin: 0 auto;
    }
    .new-detail-title{
        width: 100%;
    }
    .title-text{
        font-size: 18px;
    }
    .article{
        width: 96%;
        margin: 0 auto;
        padding: 0;
    }
    ::v-deep .article img {
        width: 100%;
        height: 100%;
    }

}
</style>