<template>
    <div>
        <div class="footer">
            <div class="footer-content">
                <div class="footer-left">
                    <div class="footer-a-link">
                        <div>友情链接：</div>
                        <div class="link-content">
                            <div
                                class="link-content-item"
                                v-for="(item, index) in links"
                                :key="index"
                            >
                                <span
                                    ><a :href="item.web_url">{{
                                        item.title
                                    }}</a></span
                                >
                                <span
                                    class="fen-ge"
                                    v-if="index != links.length - 1"
                                >
                                    |
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="lianxi">
                        <div>公司名称：深圳九壶健康科技有限公司</div>
                        <div>服务热线：400 0034 699</div>
                        <div>邮 箱：service@jiuhuht.com</div>
                        <div>
                            联系地址：深圳市罗湖区深南东路4003号世界金融中心A4503
                        </div>
                    </div>
                </div>
                <div class="footer-right">
                    <div class="footer-right-left">
                        <div class="footer-img gzh"></div>
                        <div class="footer-img-text">九壶公众号</div>
                    </div>
                    <div class="footer-right-right">
                        <div class="footer-img xcx"></div>
                        <div class="footer-img-text">九壶小程序</div>
                    </div>
                </div>
            </div>
            <div class="footer-info">
                <span class="footer-info-item"
                    >备案号：粤ICP备2022134010号
                </span>
                <span class="footer-info-item"
                    >Copyright © 2021 Theme All Rights Reserved
                </span>
                <span class="footer-info-item">技术支持：艾思软件</span>
            </div>
        </div>
    </div>
</template>
<script>
import { getLinks } from '@/api/index/index'
export default {
    data() {
        return {
            links: []
        }
    },
    methods: {
        getLinks() {
            getLinks().then(res => {
                this.links = res.data
            })
        },
    },
    created() {
        this.getLinks()
    }
}
</script>
<style scoped>
.footer {
    width: 100%;
    min-height: 200px;
    background-color: #323b44;
    padding-top: 24px;
    padding-bottom: 18px;
}
.footer-content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
}
.footer-left {
    width: 846px;

    padding-bottom: 16px;
    padding-left: 10px;
    padding-right: 10px;
}
.footer-a-link {
    display: flex;
    height: 34px;
    border-bottom: 1px solid #545f6a;
}
.footer-a-link,
.footer-a-link a {
    color: #efefef;
    text-align: left;
    font-size: 12px;
}
.link-content {
    display: flex;
}
.fen-ge {
    margin-left: 6px;
    margin-right: 8px;
}
.lianxi {
    height: 60px;
    color: #9ea7b1;
    font-size: 12px;
    text-align: left;
    padding-top: 21px;
    padding-bottom: 31px;
}
.lianxi div {
    margin-bottom: 10px;
}
.footer-right {
    display: flex;
    color: #9ea7b1;
    font-size: 12px;
}
.footer-right-left {
    margin-right: 30px;
}
.footer-img {
    width: 104px;
    height: 104px;
    background-color: #fff;
}
.footer-img-text {
    margin-top: 8px;
}
.footer-info {
    font-size: 12px;
    color: #9ea7b1;
    min-height: 54px;
    line-height: 54px;
    border-top: 1px solid #545f6a;
}
.gzh {
    background-image: url("@/assets/images/gzh.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
}
.xcx {
    background-image: url("@/assets/images/xcx.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
}
@media only screen and (max-width: 750px) {
    .header-content {
        width: 100%;
    }
    .footer-content {
        width: 100%;
        display: block;
    }
    .footer-left {
        width: 96%;
        padding: 0;
        margin: 0 auto;
    }
    .footer-a-link {
        width: 100%;
        margin: 0 auto;
    }
    .footer-right {
        width: 100%;
        justify-content: space-around;
        margin: 20px 0px;
    }
    .footer-info {
        line-height: 20px;
        padding-top: 10px;
    }
    .footer-info-item {
        display: block;
        width: 100%;
        text-align: center;
    }
}
</style>
