import axios from 'axios'
import { Message } from 'element-ui'


const service = axios.create({
    // baseURL: process.env.NODE_ENV == 'development' ? 'http://sch.test' : 'http://schedule.ibossay.com', // url = base url + request url
    baseURL: 'https://api.jiuhuht.com/', // url = base url + request url
    // baseURL: 'http://0.0.0.0:8000/', // url = base url + request url\
    timeout: 400000 // request timeout
  })

//请求拦截器
  service.interceptors.request.use(
    config => {
      return config
    },
    error => {
      // do something with request error
      return Promise.reject(error)
    }
  )

  //响应拦截器
  service.interceptors.response.use(response => {
    const res = response.data
    if(res.type == undefined && res.type != 'text/html') {
        if(res.status != 1) {
          Message.error(res.info);
          return Promise.reject(res)
        } else {
          return Promise.resolve(res)
        }
    } else {

      return res;
    }
  })

  export default service
