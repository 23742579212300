<template>
  <div>
    <header-view @toPathId="toPathId"></header-view>
    <div class="lunbo" id="home">
      <div class="lunbo-row">
        <!-- <div class="swiper-container"> -->
        <!-- <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in banners"
              :key="index"
            > -->
        <el-carousel
          :autoplay="autoplay"
          :interval="interval"
          :initial-index="initialIndex"
          :style="{
            width: '100%',
            height: bannerH,
          }"
          ref="carousel"
          @change="onChange"
        >
          <el-carousel-item
            v-for="(item, index) in banners"
            :key="index"
            name="index"
          >
            <img
              :style="{
                width: '100%',
                height: bannerH,
              }"
              v-if="!item.title.includes('mp4')"
              :src="item.img_url"
              alt=""
            />

            <div
              :style="{
                height: bannerH,
                width: playerOptions.white,
                marginLeft: 'auto',
                marginRight: 'auto',
              }"
              v-else
            >
              <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :style="{ height: bannerH }"
                :playsinline="true"
                :options="playerOptions"
                @ended="onPlayerEnded($event)"
                @loadeddata="onPlayerLoadeddata($event)"
              ></video-player>
            </div>

            <!-- <video v-show="item.title.includes('mp4')" id="video" 
                  :src="item.img_url"/> -->
          </el-carousel-item>
        </el-carousel>
        <!-- </div>

            <!-- <div
                            class="swiper-slide"
                        >
                            <img src="@/assets/images/banner.png" alt="" />
                        </div> -->
        <!-- </div> -->

        <!-- <div class="swiper-pagination"></div>
          <div
            class="swiper-button-prev swiper-button-prev swiper-button-white"
          ></div>
          <div
            class="swiper-button-next swiper-button-next swiper-button-white"
          ></div> -->
      </div>
    </div>

    <div class="home-content">
      <!-- 公司介绍 -->
      <div class="company-profile" id="guanyu">
        <div class="title">
          <span>公司介绍</span>
        </div>
        <div class="company-content">
          <div class="company-text">
            深圳九壶健康科技有限公司（简称“九壶健康”）是一家专注于饮酒生活的智能健康平台，作为智饮健康生活方式的开创者和引领者，将前沿科技融于智能健康的每一个领域，产研结合构建技术壁垒，以专业和个性化的全方位健康服务，帮助大众建立全维度、可视化的智饮生活方式，成为每个人随时随地专属的饮酒伙伴。
          </div>
          <div class="company-image">
            <div
              class="company-image-item company-image-item1"
              @mouseenter="checkImg(1, 1)"
              @mouseout="checkImg(2, 1)"
              :style="{ backgroundImage: 'url(' + imgs[0] + ')' }"
            >
              <!-- <div class="company-icon-item">
                                <img src="@/assets/images/company-item1.png" alt="">
                            </div>
                            <div class="company-icon-title">
                                <span>理念</span>
                            </div>
                            <div class="company-item-text">
                                <span>国民智饮，由我守护</span>
                            </div> -->
            </div>
            <div
              class="company-image-item company-image-item2"
              @mouseenter="checkImg(1, 2)"
              @mouseout="checkImg(2, 2)"
              :style="{ backgroundImage: 'url(' + imgs[1] + ')' }"
            >
              <!-- <div class="company-icon-item">
                                <img src="@/assets/images/company-item2.png" alt="">
                            </div>
                            <div class="company-icon-title">
                                <span>使命</span>
                            </div>
                            <div class="company-item-text">
                                <span>用科技创新为饮酒健康创造更多可能，让智能酒器走进千家万户</span>
                            </div> -->
            </div>
            <div
              class="company-image-item company-image-item3"
              @mouseenter="checkImg(1, 3)"
              @mouseout="checkImg(2, 3)"
              :style="{ backgroundImage: 'url(' + imgs[2] + ')' }"
            >
              <!-- <div class="company-icon-item">
                                <img src="@/assets/images/company-item3.png" alt="">
                            </div>
                            <div class="company-icon-title">
                                <span>愿景</span>
                            </div>
                            <div class="company-item-text">
                                <span>成为让用户获得健康的实践者<br />成为让业界兴奋的产业重塑者<br/> 成为中国智造的推动者</span>
                            </div> -->
            </div>
            <div
              class="company-image-item company-image-item4"
              @mouseenter="checkImg(1, 4)"
              @mouseout="checkImg(2, 4)"
              :style="{ backgroundImage: 'url(' + imgs[3] + ')' }"
            >
              <!-- <div class="company-icon-item">
                                <img src="@/assets/images/company-item4.png" alt="">
                            </div>
                            <div class="company-icon-title">
                                <span>价值观</span>
                            </div>
                            <div class="company-item-text">
                                <span>坚守初心，创新打造国民健康饮酒生态圈</span>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="dev-history">
        <div class="dev-title">发展历程</div>
        <div class="dev-img" v-if="!isPhone">
          <img src="@/assets/images/dev-img.png" alt="" />
        </div>
        <div v-if="isPhone" class="phone-dev-img">
          <img src="@/assets/images/phone-deb-img.png" alt="" />
        </div>
        <div id="chanpin"></div>
      </div>
      <!-- 产品介绍 -->
      <div class="prod-intr">
        <div class="prod-title">产品介绍</div>
        <div class="prod-content">
          <div class="prod-content-left">
            <img src="@/assets/images/prod-left.jpg" alt="" />
          </div>
          <div class="prod-content-right">
            <div class="prod-right-title">智樽™智能酒壶</div>
            <div class="prod-right-text">
              智樽™为九壶健康旗下品牌，专注于探索饮酒计量与健康管理的智能需求，
              为饮酒人士提供集智能计量、数据监测、健康管理、社交互动于一体的数
              智产品与体验，帮助大众更轻松、更便捷的在生活中实现健康饮酒管理。
            </div>
          </div>
        </div>
      </div>

      <div class="user-experience" id="fuwu">
        <div class="user-left">
          <ul style="overflow: auto" class="user-ul">
            <div
              :class="
                index == messLists.length - 1
                  ? 'user-left-item user-last-item'
                  : 'user-left-item'
              "
              v-for="(item, index) in messLists"
              :key="index"
            >
              <div class="left-item-left">
                <img :src="item.head" alt="" />
              </div>
              <div class="left-item-right">
                <div class="left-item-right-title">
                  {{ item.nickname }}
                </div>
                <div class="left-item-right-text">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div class="user-center">
          <div class="user-center-left">
            <div>用户体验</div>
          </div>
          <div class="user-center-right" @click="toPath('/news')">
            <div>新闻中心</div>
          </div>
        </div>
        <div class="user-right">
          <ul style="overflow: auto" class="user-ul">
            <div
              :class="
                index == homeNewsLists.length - 1
                  ? 'user-right-item user-right-last'
                  : 'user-right-item'
              "
              @click="toPath('/detail?id=' + item.id)"
              v-for="(item, index) in homeNewsLists"
              :key="index"
            >
              <div class="user-right-left">
                <img :src="item.img_url" alt="" />
              </div>
              <div class="user-right-right">
                <div class="user-right-right-title">
                  {{ item.title }}
                </div>
                <div class="user-right-right-text">
                  <div class="right-text-news">
                    {{ item.desc }}
                  </div>
                  <div class="right-text-time" v-if="!isPhone">
                    {{ item.create_date }}
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <!-- 合作机构 -->
      <div class="coop-inst">
        <div class="coop-title">合作机构</div>
        <div class="coop-content">
          <div
            :class="index == 0 ? 'coop-item coop-one' : 'coop-item'"
            v-for="(item, index) in partners"
            :key="index"
          >
            <img :src="item.img_url" alt="" />
          </div>
        </div>
        <div class="back-top" @click="backTop">
          <img src="@/assets/images/top.png" alt="" />
        </div>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
</template>
<script>
import HeaderView from "@/components/HomeHeaderView.vue";
import FooterView from "@/components/FooterView.vue";
import { getBanners, homeNews, getMess, getPartner } from "@/api/index/index";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      imgs: [
        require("@/assets/images/company1.png"),
        require("@/assets/images/company2.png"),
        require("@/assets/images/company3.png"),
        require("@/assets/images/company4.png"),
      ],
      banners: [],
      homeNewsLists: [],
      messLists: [],
      partners: [],
      isPhone: false,
      intId: [],
      autoplay: false,
      interval: 3000,
      initialIndex: 0, //轮播默认滑动到第一个
      bannerH: "34VW",
      //视频参数
      playerOptions: {
        autoplay: true, //如果true,浏览器准备好时开始回放。实现自动播放，autoplay、muted都设置为true
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
    };
  },
  created() {
    this.isPhone = this.isMobileDevice();
  },
  mounted() {
    this.initSwiper();
    this.getBanners();
    this.homeNews();
    this.getMess();
    this.getPartner();
    setTimeout(() => {
      this.test1();
    }, 200);
  },
  methods: {
    onMulted(index) {
      this.playerOptions.muted = !this.playerOptions.muted;
    },
    onPlayerLoadeddata(player) {
      // 获取资源总时长
      this.duration = player.cache_.duration;
    },
    //监听媒体是否已到达结尾，播放完
    onPlayerEnded(player) {
      this.autoplay = true;
      this.interval = 1000;
    },
    // 监听轮播图改变
    onChange(index, oldIndex) {
      console.log("=====" + index + "=====" + oldIndex);

      if (this.banners[oldIndex].img_url.includes("mp4")) {
        this.$refs.videoPlayer[oldIndex].player.pause();
      }

      // 如果是视频,就等播放完再进行下一个
      if (this.banners[index].title.includes("mp4")) {
        this.$refs.videoPlayer[index].player.src(this.banners[index]["src"]); // 重置进度条,再次轮播时让视频重新播放

        // this.$refs.videoPlayer[index - 2].setAttribute("poster",this.banners[index].poster)   ;

        this.interval = this.duration * 1000;
        this.autoplay = false;
      }
    },

    toPathId(id) {
      var a = document.createElement("a");
      a.href = id;
      a.click();
    },
    test1() {
      var defaults = {
        speed: 30, //婊氬姩閫熷害涓�0-100涔嬮棿
        direction: "vertical", //鏂瑰悜锛歷ertical鍚戜笂婊氬姩,horizantal鍚戝乏婊氬姩
      };

      var opts = $.extend({}, defaults);
      var that = this;
      $(".coop-content").each(function (i) {
        var speed =
          0 < 100 - opts["speed"] && 100 - opts["speed"] <= 100
            ? 100 - opts["speed"]
            : 30;
        var direction = opts["direction"];
        var _this = $(this);

        that.intId[i] = setInterval(() => {
          var sh = $(".coop-content").outerWidth(true) + 226 * 6;
          that.marquee(_this, sh, direction);
        }, speed);

        _this.hover(
          function () {
            clearInterval(that.intId[i]);
          },
          function () {
            //
            that.intId[i] = setInterval(() => {
              var sh = $(".coop-content").outerWidth(true) + 226 * 6;
              that.marquee(_this, sh, direction);
            }, speed);
          }
        );
      });
    },
    marquee(obj, step, direction) {
      let that = this;
      var s = Math.abs(parseInt($(".coop-one").css("margin-left")));

      $(".coop-one").animate(
        {
          marginLeft: "-=1",
        },
        0,
        () => {
          if (s >= step) {
            $(".coop-one").slice(0, 1).appendTo($(".coop-one"));
            $(".coop-one").css("margin-left", 0);
            that.intId.forEach((item) => {
              clearInterval(item);
            });
          }
        }
      );
    },
    isMobileDevice() {
      //判断当前设备是否为移动端
      const ua = navigator.userAgent.toLowerCase();
      const t1 =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          ua
        );
      const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
      return t1 || t2;
    },
    getBanners() {
      getBanners().then((res) => {
        this.banners = res.data;
        this.banners.unshift(
          {
            title: "mp41",
            img_url: "https://api.jiuhuht.com/upload/video/wanzhengban4k.mov",
            poster: "https://api.jiuhuht.com/upload/video/jiujuyouli.jpg",
            type: "video/mov",

            src: "https://api.jiuhuht.com/upload/video/wanzhengban4k.mov",
          },
          {
            title: "mp42",
            img_url:
              "https://api.jiuhuht.com/upload/video/shiyongjiaocheng1016.mp4",
            poster: "https://api.jiuhuht.com/upload/video/shiyonjiaocheng.jpg",
            type: "video/mp4",

            src: "https://api.jiuhuht.com/upload/video/shiyongjiaocheng1016.mp4",
          }
        );
       
        this.playerOptions.sources.push(
          {
            // type: "video/mov",
            title: "mp41",

            src: "https://api.jiuhuht.com/upload/video/wanzhengban4k.mov"
          }
        );
        console.log("urls-video", this.playerOptions.sources);
      });
    },
    homeNews() {
      homeNews().then((res) => {
        this.homeNewsLists = res.data;
      });
    },
    getMess() {
      getMess().then((res) => {
        console.log(111, res);
        this.messLists = res.data;
      });
    },
    getPartner() {
      getPartner().then((res) => {
        this.partners = res.data;
      });
    },
    initSwiper() {
      setTimeout(() => {
        new Swiper(".swiper-container", {
          autoplay: 2000, //可选选项，自动滑动
          prevButton: ".swiper-button-prev",
          nextButton: ".swiper-button-next",
          pagination: ".swiper-pagination",
        });
      }, 1000);
    },
    backTop() {
      $("html,body").animate({ scrollTop: "0px" }, 800);
    },
    toPath(path) {
      this.$router.push({ path: path });
    },
    checkImg(type, index) {
      if (type == 1) {
        if (index == 1) {
          this.$set(this.imgs, 0, require("@/assets/images/company1-s.png"));
          // this.imgs[0] = require("@/assets/images/company1-s.png")
        } else if (index == 2) {
          this.$set(this.imgs, 1, require("@/assets/images/company2-s.png"));
          // $('.company-image-item2').css('background-image','url(' + require('@/assets/images/company2-s.png') + ')')
        } else if (index == 3) {
          this.$set(this.imgs, 2, require("@/assets/images/company3-s.png"));
          // $('.company-image-item3').css('background-image','url(' + require('@/assets/images/company3-s.png') + ')')
        } else {
          this.$set(this.imgs, 3, require("@/assets/images/company4-s.png"));
          // $('.company-image-item4').css('background-image','url(' + require('@/assets/images/company4-s.png') + ')')
        }
      } else {
        if (index == 1) {
          this.$set(this.imgs, 0, require("@/assets/images/company1.png"));
        } else if (index == 2) {
          this.$set(this.imgs, 1, require("@/assets/images/company2.png"));
          // $('.company-image-item2').css('background-image','url(' + require('@/assets/images/company2.png') + ')')
        } else if (index == 3) {
          this.$set(this.imgs, 2, require("@/assets/images/company3.png"));
          // $('.company-image-item3').css('background-image','url(' + require('@/assets/images/company3.png') + ')')
        } else {
          this.$set(this.imgs, 3, require("@/assets/images/company4.png"));
          // $('.company-image-item4').css('background-image','url(' + require('@/assets/images/company4.png') + ')')
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home-content {
  width: 100%;
  background-color: #f8f8f8;
}
.company-profile {
  width: 1400px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  z-index: 9000;
  overflow: hidden;
  padding-bottom: 33px;
}
.swiper-slide {
  max-height: 640px !important;
}
.swiper-slide img {
  width: 100%;
  max-height: 640px !important;
}
.title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 56px;
}
.company-content {
  width: 1298px;
  margin: 0 auto;
}
.company-text {
  font-size: 18px;
  color: #717171;
  text-align: left;
  margin-top: 54px;
  margin-bottom: 72px;
  line-height: 30px;
}
.company-item-text {
  font-size: 14px;
  color: #fff;
}

.multe {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  z-index: 10;
}
.company-image {
  display: flex;
  justify-content: space-between;
}
.company-image-item {
  width: 310px;
  height: 210px;
}

.company-image-item1 {
  /* background-image: url('@/assets/images/company1.png'); */
  background-repeat: no-repeat;
  background-size: 100%;
}
.company-image-item2 {
  background-image: url("@/assets/images/company2.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.company-image-item3 {
  background-image: url("@/assets/images/company3.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.company-image-item4 {
  background-image: url("@/assets/images/company4.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.company-icon-item {
  margin-top: 47px;
  margin-bottom: 18px;
}
.company-icon-title {
  font-size: 20px;
  color: #fff;
}
.dev-history {
  width: 1400px;
  /* height: 486px; */
  background-color: #fff;
  border-radius: 20px;
  margin: 70px auto 71px auto;
  overflow: hidden;
}
.dev-title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 69px;
}
.dev-img {
  margin-top: 60px;
}
.dev-img img {
  width: 1358px;
  height: 285px;
}

.prod-intr {
  width: 1400px;
  padding-top: 71px;
  margin: auto;
}

.prod-title {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
}
.prod-content {
  width: 1400px;
  min-height: 380px;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
}
.prod-right-title {
  color: #717171;
  font-size: 24px;
}
.prod-right-text {
  color: #717171;
  font-size: 16px;
  line-height: 30px;
  margin-top: 25px;
}
.prod-content-left {
  margin-right: 25px;
  margin-top: 24px;
  margin-left: 25px;
}
.prod-content-left img {
  width: 600px;
  height: 332px;
}
.prod-content-right {
  text-align: left;
  margin-top: 24px;
}
.user-experience {
  width: 1400px;
  min-height: 570px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  padding-top: 159px;
}
.user-left {
  width: 442px;
  height: 520px;
  //   padding-left: 25px;
  //   padding-right: 25px;
  //   padding-top: 19px;
  //   padding-bottom: 26px;
  padding: 20px;
}

.user-ul {
  height: 100%;
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  width: 5px; /* 纵向滚动条 宽度 */
  background: #fff; /* 整体背景 */
}
.user-right {
  width: 424px;
  height: 530px;
  padding: 20px;
}
.user-left,
.user-right {
  background-color: #fff;
  border-radius: 20px;
}
.user-center {
  width: 400px;
  background-image: url("@/assets/images/user-ex.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 20px;
  top: -90px;
}
.user-left-item {
  display: flex;
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #f4f4f4;
}
.user-left-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.user-last-item {
  border-bottom: 0;
}
.left-item-left img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 10px;
}
.left-item-right {
  text-align: left;
}
.left-item-right-title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 10px;
}
.left-item-right-text {
  color: #888888;
  font-size: 12px;
  line-height: 18px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.user-center-left {
  color: #333333;
  font-size: 30px;
  font-weight: 500;
  position: absolute;
  top: 35px;
  left: 24px;
}
.user-center-right {
  position: absolute;
  right: 17px;
  bottom: 42px;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  cursor: pointer;
}
.user-right-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 14px;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}
.user-right-last {
  border-bottom: 0;
}
.user-right-left {
  margin-right: 18px;
}
.user-right-left img {
  width: 90px;
  height: 75px;
}
.user-right-right-text {
  display: flex;
  height: 40px;
  text-align: left;
  align-items: center;
}
.user-right-right-title {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  text-align: left;
}
.right-text-news {
  width: 236px;
  font-size: 14px;
  color: #888;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.right-text-time {
  color: #ccc;
  font-size: 12px;
}
.coop-inst {
  width: 100%;
  min-height: 312px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
.back-top {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 20px;
  bottom: 15%;
  z-index: 9999;
}
.back-top img {
  width: 50px;
  height: 50px;
}
.coop-content {
  display: flex;
}
.coop-title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 59px;
  margin-bottom: 82px;
}
.coop-item {
  flex: 0 0 auto;
  width: 226px;
  height: 95px;
  margin-right: 8px;
}
.coop-item img {
  width: 100%;
  filter: grayscale(100%);
}
.coop-item img:hover {
  filter: grayscale(0%);
}

// -------------------------------------------------------------
@media screen and (max-width: 750px) {
  .lunbo {
    margin-top: 62px;
  }

  .lunbo,
  .lunbo-row,
  .swiper-slide,
  .swiper-slide-active {
    width: 100% !important;
  }
  .swiper-slide-active img {
    width: 100% !important;
    /* height: 100%;  */
  }
  .company-profile {
    width: 100%;
    min-height: 531px;
    top: 0;
  }
  .company-content {
    width: 96%;
    margin: 0 auto;
    font-size: 12px;
  }
  .company-text {
    white-space: pre-wrap;
    font-size: 14px;
  }
  .company-image {
    flex-wrap: wrap;
  }
  .company-image-item {
    width: 48%;
    margin: 0 auto;
    margin-bottom: 8px;
    height: 130px;
  }
  .dev-history {
    width: 100%;
  }
  .phone-dev-img img {
    width: 90%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .prod-intr {
    width: 100%;
    padding-top: 0px;
  }
  .prod-content {
    width: 100%;
    display: block;
  }
  .prod-content-left {
    width: 100%;
    margin: 0;
  }
  .prod-content-left img {
    width: 96%;
    height: auto;
    margin: 0 auto;
  }
  .prod-right-title {
    width: 96%;
    margin: 0 auto 10px auto;
    text-align: center;
  }
  .prod-right-text {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
    font-size: 14px;
  }
  .user-experience {
    width: 96%;
    display: block;
    margin: 0 auto;
    padding-top: 50px;
  }
  .user-left {
    width: 100%;
    padding: 20px 0px 20px 0px;
    // border: 1px solid red;

    .left-item-right {
      width: 72%;
    }
  }
  .coop-inst {
    width: 100%;
  }
  .back-top {
    display: none;
  }

  .coop-item {
    width: 50%;
    margin: 0;
  }
  .coop-item img {
    filter: grayscale(0%);
  }
  .user-center {
    width: 100%;
    height: 530px;
    top: 0;
    margin-top: 50px;
    border-radius: 20px;
  }
  .user-right {
    margin-top: 30px;
    width: auto !important;
    height: auto;
    padding: 25px 10px 15px 10px;
    // border: 1px solid red;
  }

  .user-right-item {
    padding: 0;
    display: left;
    width: 100%;
    margin-bottom: 15px;
    clear: both;
    // border: 1px solid green;
  }

  .user-right-left {
    float: left;
    // border: 1px solid blue;
  }
  .user-right-right {
    float: right;
    // border: 1px solid black;
    width: 65%;
  }
  .user-right-right-title {
    width: calc(100% - 2px);
  }
  .user-right-right-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>